@font-face {
    font-family: 'buk';
    src:  url('assets/fonts/buk.eot?ckc2e3');
    src:  url('assets/fonts/buk.eot?ckc2e3#iefix') format('embedded-opentype'),
      url('assets/fonts/buk.ttf?ckc2e3') format('truetype'),
      url('assets/fonts/buk.woff?ckc2e3') format('woff'),
      url('assets/fonts/buk.svg?ckc2e3#buk') format('svg');

    font-weight: normal;
    font-style: normal;
}

[class^="ic-"], [class*=" ic-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'buk' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ic-google:before {
  content: "\e923";
}

.ic-steemconnect:before {
  content: "\e925";
}

.ic-steem-gray:before {
  content: "\e924";
}

.ic-steem .path1:before {
  content: "\e921";
  color: rgb(22, 80, 152);
}
.ic-steem .path2:before {
  content: "\e922";
  margin-left: -0.94140625em;
  color: rgb(92, 157, 213);
}

.ic-two_page:before {
    content: "\e91e";
}

.ic-one_page:before {
    content: "\e91f";
}

.ic-filter_drop:before {
    content: "\e90d";
}

.ic-send_email:before {
    content: "\e912";
}

.ic-fullscreen_enter:before {
    content: "\e913";
}

.ic-fullscreen_exit:before {
    content: "\e914";
}

.ic-width_fit:before {
    content: "\e90e";
}

.ic-fit:before {
    content: "\e90f";
}

.ic-checkmark:before {
    content: "\e91d";
}

.ic-checked-circle-full:before {
    content: "\e91a";
}

.ic-bookmark_page:before {
    content: "\e91b";
}

.ic-highlight_page:before {
    content: "\e91c";
}

.ic-gift:before {
    content: "\e919";
}

.ic-grid:before {
    content: "\e918";
}

.ic-nm1:before {
    content: "\b098";
}

.ic-nm2:before {
    content: "\b214";
}

.ic-nm3:before {
    content: "\ba85";
}

.ic-nm4:before {
    content: "\c870";
}

.ic-ng1:before {
    content: "\3134";
}

.ic-ng2:before {
    content: "\b204";
}

.ic-ng3:before {
    content: "\ace0";
}

.ic-ng4:before {
    content: "\b515";
}

.ic-kb1:before {
    content: "\4b";
}

.ic-kb2:before {
    content: "\6f";
}

.ic-kb3:before {
    content: "\50";
}

.ic-kb4:before {
    content: "\75";
}

.ic-kb5:before {
    content: "\62";
}

.ic-kb6:before {
    content: "\bc14";
}

.ic-kb7:before {
    content: "\d0d5";
}

.ic-tag:before {
    content: "\e917";
}

.ic-smile:before {
    content: "\e90c";
}

.ic-sad:before {
    content: "\e911";
}

.ic-pinch:before {
    content: "\e915";
}

.ic-zoom_in:before {
    content: "\e916";
}

.ic-no_results:before {
    content: "\e90a";
}

.ic-search_start:before {
    content: "\e90b";
}

.ic-newlink:before {
    content: "\2e45";
}

.ic-googleplus:before {
    content: "\2e46";
}

.ic-user:before {
    content: "\2e47";
}

.ic-aa:before {
    content: "\2e48";
}

.ic-menu:before {
    content: "\2e49";
}

.ic-toc:before {
    content: "\2e4a";
}

.ic-dot-menu:before {
    content: "\2e4b";
}

.ic-home:before {
    content: "\2e4c";
}

.ic-highlight:before {
    content: "\2e4d";
}

.ic-share:before {
    content: "\2e4e";
}

.ic-settings:before {
    content: "\2e4f";
}

.ic-removal:before {
    content: "\2e51";
}

.ic-copy:before {
    content: "\2e52";
}

.ic-conversion:before {
    content: "\2e53";
}

.ic-sync-error:before {
    content: "\2e54";
}

.ic-upload:before {
    content: "\2e55";
}

.ic-download:before {
    content: "\2e56";
}

.ic-clock:before {
    content: "\2e5a";
}

.ic-checked-circle .path1:before {
    content: "\2e5b";
}

.ic-checked-circle .path2:before {
    content: "\2e5c";
    margin-left: -1em;
}

.ic-exclamation-mark:before {
    content: "\2e5e";
}

.ic-book:before {
    content: "\2e5f";
}

.ic-circle:before {
    content: "\2e60";
}

.ic-bookmark-selected:before {
    content: "\2e61";
}

.ic-library:before {
    content: "\2e6b";
}

.ic-dashboard:before {
    content: "\2e6d";
}

.ic-login:before {
    content: "\2e6e";
}

.ic-logout:before {
    content: "\2e6f";
}

.ic-unlock:before {
    content: "\2e70";
}

.ic-lock:before {
    content: "\2e71";
}

.ic-stronglock:before {
    content: "\2e72";
}

.ic-layout-expand:before {
    content: "\2e73";
}

.ic-layout:before {
    content: "\2e74";
}

.ic-sun:before {
    content: "\2e75";
}

.ic-moon:before {
    content: "\2e76";
}

.ic-stacked-book:before {
    content: "\2e78";
}

.ic-stacked-coin:before {
    content: "\2e79";
}

.ic-plus:before {
    content: "\2e7a";
}

.ic-minus:before {
    content: "\2e7b";
}

.ic-backward:before {
    content: "\2e7d";
}

.ic-cart:before {
    content: "\2e7e";
}

.ic-info:before {
    content: "\2e7f";
}

.ic-more:before {
    content: "\2fd6";
}

.ic-info-filled:before {
    content: "\2fd7";
}

.ic-facebook-circle:before {
    content: "\2fd8";
}

.ic-twitter-circle:before {
    content: "\2fd9";
}

.ic-home-circle:before {
    content: "\2fda";
}

.ic-facebook:before {
    content: "\2fdb";
}

.ic-twitter:before {
    content: "\2fdc";
}

.ic-linkedin:before {
    content: "\2fdd";
}

.ic-v-expand:before {
    content: "\2fde";
}

.ic-openbook:before {
    content: "\2fdf";
}

.ic-elevator:before {
    content: "\2fe0";
}

.ic-dropdown:before {
    content: "\2fe1";
}

.ic-signup:before {
    content: "\2fe2";
}

.ic-link:before {
    content: "\2fe3";
}

.ic-switch-off:before {
    content: "\2fe4";
}

.ic-switch-on:before {
    content: "\2fe5";
}

.ic-expand:before {
    content: "\2fe6";
}

.ic-reader:before {
    content: "\2fe7";
}

.ic-d_price:before {
    content: "\e000";
}

.ic-d_bookinfo:before {
    content: "\e001";
}

.ic-d_share:before {
    content: "\e002";
}

.ic-d_ticket:before {
    content: "\e003";
}

.ic-d_quote:before {
    content: "\e004";
}

.ic-d_imprint:before {
    content: "\e005";
}

.ic-d_stats:before {
    content: "\e006";
}

.ic-d_page:before {
    content: "\e007";
}

.ic-d_admin:before {
    content: "\e008";
}

.ic-d_sale:before {
    content: "\e009";
}

.ic-d_book:before {
    content: "\e00a";
}

.ic-logo:before {
    content: "\e00b";
}

.ic-history:before {
    content: "\e00c";
}

.ic-search:before {
    content: "\e00d";
}

.ic-bookmark:before {
    content: "\e00e";
}

.ic-big_logo:before {
    content: "\e00f";
    color: #3c3c3b;
}

.ic-double_right:before {
    content: "\e010";
}

.ic-page:before {
    content: "\e013";
}

.ic-scroll:before {
    content: "\e014";
}

.ic-checked-circle-color .path1:before {
    content: "\e015";
    color: rgb(255, 255, 255);
}

.ic-checked-circle-color .path2:before {
    content: "\e016";
    margin-left: -1em;
    color: rgb(83, 115, 183);
}

.ic-checked-circle-color .path3:before {
    content: "\e017";
    margin-left: -1em;
    color: rgb(83, 115, 183);
}

.ic-exclamation-mark-color .path1:before {
    content: "\e018";
    color: rgb(255, 255, 255);
}

.ic-exclamation-mark-color .path2:before {
    content: "\e019";
    margin-left: -1em;
    color: rgb(211, 34, 54);
}

.ic-exclamation-mark-color .path3:before {
    content: "\e01a";
    margin-left: -1em;
    color: rgb(211, 34, 54);
}

.ic-exclamation-mark-color .path4:before {
    content: "\e01b";
    margin-left: -1em;
    color: rgb(211, 34, 54);
}

.ic-author:before {
    content: "\e01c";
}

.ic-wiki:before {
    content: "\e01d";
}

.ic-earth:before {
    content: "\e01e";
}

.ic-golink:before {
    content: "\e01f";
}

.ic-long_arrow_left:before {
    content: "\e020";
}

.ic-long_arrow_right:before {
    content: "\e021";
}

.ic-free:before {
    content: "\e022";
}

.ic-free_ko:before {
    content: "\e023";
}

.ic-angle_book:before {
    content: "\e024";
}

.ic-library_home:before {
    content: "\e025";
}

.ic-check-box-checked .path1:before {
    content: "\e027";
    color: rgb(170, 167, 166);
}

.ic-check-box-checked .path2:before {
    content: "\e028";
    margin-left: -1em;
    color: rgb(77, 117, 184);
}

.ic-alarm:before {
    content: "\e029";
}

.ic-click:before {
    content: "\e030";
}

.ic-quotes:before {
    content: "\e031";
}

.ic-top:before {
    content: "\e032";
}

.ic-check-box:before {
    content: "\e900";
    color: #aaa7a6;
}

.ic-x:before {
    content: "\e901";
}

.ic-right_arrow:before {
    content: "\e902";
}

.ic-left_arrow:before {
    content: "\e903";
}

.ic-side_menu:before {
    content: "\e904";
}

.ic-dictionary:before {
    content: "\e905";
}

.ic-buklogo:before {
    content: "\e906";
}

.ic-clear:before {
    content: "\e907";
}

.ic-double_left:before {
    content: "\e908";
}

.ic-textcopy:before {
    content: "\e909";
}

.ic-home_selected:before {
    content: "\e910";
}
