@import 'buk-icon';
@import 'material-theme';
@import 'buk-palette';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  margin: 0;

  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', 'Noto Sans Korean', 'Apple SD Gothic Neo',
    'Helvetica Neue', Arial, 'Nanum Gothic', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// // Fix bug: https://github.com/angular/components/issues/11845
// .mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
//   border-top-width: 0.421875em;
// }

.ck-editor__editable.ck-content {
  --ck-content-font-face: sans-serif;
  line-height: 1.7;
  font-size: 18px;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 1.4;
  }

  .image {
    margin: 1em auto;

    max-width: 100%;
    /*
    The `<figure>` element for resized images must not use `display:table` as browsers do not support `max-width` for it well.
    See https://stackoverflow.com/questions/4019604/chrome-safari-ignoring-max-width-in-table/14420691#14420691 for more.
    Fortunately, since we control the width, there is no risk that the image will look bad.
    */
    display: block;
    box-sizing: border-box;

    & > figcaption {
      /* The `<figure>` element uses `display:block`, so `<figcaption>` also has to. */
      display: block;
    }
  }
}

body > [id*='bukio-brick-mermaid-'] {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
}

mat-form-field,
mat-form-field * {
  box-sizing: content-box;
}

.mat-dialog-title,
.mat-dialog-content,
.mat-dialog-actions {
  box-sizing: content-box;
}

.mat-dialog-actions {
  justify-content: flex-end;

  .expand {
    flex: 1;
  }
}

.mat-dialog-content.prevent-scroll::after {
  content: ' ';
  display: block;
  height: 1em;
}

.mat-slide-toggle-content {
  font-size: inherit;
}

.mat-tooltip {
  font-size: 12px;
}

.mat-tooltip.is-multiline {
  white-space: pre-line;
}

.mat-form-field.paddingless {
  margin-bottom: -1.25em;
}

.mat-form-field.full-width {
  width: 100%;
}

.mat-form-field.no-placeholder {
  .mat-form-field-infix {
    border-top-width: 0;
  }
}

// .mat-form-field.prefix-align-bottom {
//   .mat-form-field-prefix {
//     align-self: flex-end;
//   }
// }

// .mat-form-field.suffix-align-bottom {
//   .mat-form-field-suffix {
//     align-self: flex-end;
//   }
// }

.mat-icon-button.paddingless {
  margin-top: -13px;
  margin-bottom: -13px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-button {
  text-decoration: underline;
  cursor: pointer;
}

.text-primary {
  color: rgba(0, 0, 0, 0.87);
}

.text-secondary {
  color: rgba(0, 0, 0, 0.54);
}

.text-accent {
  color: $accent;
}

.text-warn {
  color: $text-warn;
}

.text-positive {
  color: $text-positive;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word-break-keep-all {
  word-break: keep-all;
}

.icon-active {
  color: rgba(0, 0, 0, 0.54);
}

.icon-inactive {
  color: rgba(0, 0, 0, 0.38);
}

.mat-like-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin-left: 1px;
  margin-bottom: 4px;
}

.vertical-middle {
  vertical-align: middle;
}

.flex {
  display: flex;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &:not(.flex-dir-column),
  &.flex-dir-row {
    flex-direction: row;

    &.flex-h-start {
      justify-content: flex-start;
    }
    &.flex-h-end {
      justify-content: flex-end;
    }
    &.flex-h-center {
      justify-content: center;
    }
    &.flex-h-space {
      justify-content: space-between;
    }

    &.flex-v-start {
      align-items: flex-start;
    }
    &.flex-v-end {
      align-items: flex-end;
    }
    &.flex-v-center {
      align-items: center;
    }
  }

  &.flex-dir-column {
    flex-direction: column;
  }

  &.flex-dir-column {
    &.flex-v-start {
      justify-content: flex-start;
    }
    &.flex-v-end {
      justify-content: flex-end;
    }
    &.flex-v-center {
      justify-content: center;
    }
    &.flex-v-space {
      justify-content: space-between;
    }

    &.flex-h-start {
      align-items: flex-start;
    }
    &.flex-h-end {
      align-items: flex-end;
    }
    &.flex-h-center {
      align-items: center;
    }
  }

  &.flex-center {
    align-items: center;
    justify-content: center;
  }

  > .flex-item-expand {
    flex: 1 1 auto;
  }

  > .flex-item-fit {
    flex: 0 0 auto;
  }

  > .flex-item-half {
    flex: 0 0 50%;
  }

  > .flex-item-full {
    flex: 0 0 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.marginless {
    margin: 0;
  }
}

a {
  color: #4a77bb;
  text-decoration: none;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

table.app-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);
    word-break: keep-all;
  }

  tbody {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }

  tbody tr + tr {
    border-top: 1px solid #e9e9e9;
  }

  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  td,
  th {
    padding: 4px 12px;

    &.center {
      text-align: center;
    }

    &.content-fit {
      width: 1%;
      white-space: nowrap;
    }
  }

  &.is-tight {
    td,
    th {
      padding: 12px 14px;
    }
  }
}

table.cp-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    font-weight: normal;
    word-break: keep-all;
    background-color: #f9fafb;
    white-space: nowrap;
  }

  tr:first-child {
    border-top: 1px solid #e9e9e9;
  }

  tr:last-child {
    border-bottom: 1px solid #e9e9e9;
  }

  tr + tr {
    border-top: 1px solid #e9e9e9;
  }

  td,
  th {
    padding: 12px 24px;

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.content-fit {
      width: 1%;
      white-space: nowrap;
    }
  }

  &.is-tight {
    td,
    th {
      padding: 12px 14px;
    }
  }

  &.is-striped {
    tbody tr:nth-child(even) {
      background: #f9fafb;
    }
  }
}

.ck {
  // override ckeditor's placeholder text color
  --ck-color-engine-placeholder-text: rgba(0, 0, 0, 0.38);
}

.ck.ck-card-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-card-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw {
  max-height: calc(50vh - 56px - 42px);
}

.ck.ck-card-dropdown .ck-dropdown__panel.ck-dropdown__panel_se,
.ck.ck-card-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  max-height: calc(50vh - 56px - 56px - 42px);
}

.cp-global {
  &.context-switcher {
    height: auto;
    width: auto !important;

    .mat-select-value {
      max-width: 200px;
      width: auto;
      color: inherit;
    }

    .mat-select-arrow {
      color: inherit !important;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-input-wrapper,
    .mat-form-field-wrapper {
      padding: 0;
    }
    .mat-input-wrapper,
    .mat-form-field-wrapper,
    .mat-input-flex {
      height: auto;
    }

    .mat-form-field-infix {
      width: auto;
    }

    .mat-input-infix,
    .mat-form-field-infix {
      border: none;
    }

    mat-select .mat-select-value {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.setting {
    .url,
    .followers {
      .mat-input-underline,
      .mat-form-field-underline {
        display: none;
      }
    }

    .mat-form-field {
      width: 570px;

      .mat-input-underline,
      .mat-form-field-underline {
        background-color: rgba(0, 0, 0, 0.12);
      }

      .placeholder {
        display: none;

        position: absolute;
        margin-top: -18px;
        color: #9f9f9f;
        pointer-events: none;
        user-select: none;
        user-drag: none;
      }

      .padded-facebook {
        padding-left: 200px;
      }
      .padded-twitter {
        padding-left: 139px;
      }

      &.mat-form-field-should-float {
        .placeholder {
          display: block;
        }
      }
    }
  }

  // discount price color
  &.discount_option {
    padding: 0 0 0 8px;
    .mat-option-text {
      font-size: 14px;

      .discount_text {
        color: #d52c41;
      }
    }
  }
}

[fxLayoutSub] {
  display: flex;
  flex-direction: row;

  &[fxLayoutSub='row'] {
    flex-direction: row;
  }

  &[fxLayoutSub='column'] {
    flex-direction: column;
  }

  &[fxLayoutAlignSub^='start'] {
    justify-content: flex-start;
  }

  &[fxLayoutAlignSub^='center'] {
    justify-content: center;
  }

  &[fxLayoutAlignSub^='end'] {
    justify-content: flex-end;
  }

  &[fxLayoutAlignSub^='space-between'] {
    justify-content: space-between;
  }

  &[fxLayoutAlignSub^='space-around'] {
    justify-content: space-around;
  }

  &[fxLayoutAlignSub^='space-evenly'] {
    justify-content: space-evenly;
  }

  &[fxLayoutAlignSub$=' start'] {
    align-items: flex-start;
    align-content: flex-start;
  }

  &[fxLayoutAlignSub$=' center'] {
    align-items: center;
    align-content: center;
  }

  &[fxLayoutAlignSub$=' end'] {
    align-items: flex-end;
    align-content: flex-end;
  }

  &[fxLayoutAlignSub$=' space-around'] {
    align-items: space-around;
    align-content: space-around;
  }

  &[fxLayoutAlignSub$=' space-between'] {
    align-items: space-between;
    align-content: space-between;
  }

  &[fxLayoutAlignSub$=' space-evenly'] {
    align-items: space-evenly;
    align-content: space-evenly;
  }

  &[fxLayoutSub='row'][fxLayoutAlignSub$='stretch'] {
    max-height: 100%;
  }

  &[fxLayoutSub='column'][fxLayoutAlignSub$='stretch'] {
    max-width: 100%;
  }

  &[fxLayoutAlignSub$=' baseline'] {
    align-items: baseline;
  }
}
